export default class CodeMaster {
  kbn: string;
  code: string;
  name: string;
  bikou: string;
  comcha1: string;
  comcha2: string;
  comcha3: string;
  comnum1: string;
  comnum2: string;
  comnum3: string;
  comdat1: string;
  comdat2: string;
  comdat3: string;
  delflg: string;
  sort: string;
  constructor(
    kbn = "",
    code = "",
    name = "",
    bikou = "",
    comcha1 = "",
    comcha2 = "",
    comcha3 = "",
    comnum1 = "",
    comnum2 = "",
    comnum3 = "",
    comdat1 = "",
    comdat2 = "",
    comdat3 = "",
    delflg = "",
    sort = ""
  ) {
    this.kbn = kbn;
    this.code = code;
    this.name = name;
    this.bikou = bikou;
    this.comcha1 = comcha1;
    this.comcha2 = comcha2;
    this.comcha3 = comcha3;
    this.comnum1 = comnum1;
    this.comnum2 = comnum2;
    this.comnum3 = comnum3;
    this.comdat1 = comdat1;
    this.comdat2 = comdat2;
    this.comdat3 = comdat3;
    this.delflg = delflg;
    this.sort = sort;
  }
}

<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-autocomplete
          v-model="selectCodeGroup"
          :items="codegroups"
          label="区分"
          :rules="[Rules.Required]"
          outlined
          :disabled="!add"
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-text-field
          v-model="code"
          label="コード"
          prepend-icon="mdi-text"
          :disabled="!add"
          outlined
          maxlength="6"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="name"
          label="名称"
          prepend-icon="mdi-text"
          outlined
          maxlength="50"
        ></v-text-field>
        <v-text-field
          v-model="bikou"
          label="備考"
          prepend-icon="mdi-text"
          outlined
          maxlength="50"
        ></v-text-field>
        <v-text-field
          v-model="comcha1"
          label="文字項目1"
          prepend-icon="mdi-text"
          outlined
          maxlength="100"
        ></v-text-field>
        <v-text-field
          v-model="comcha2"
          label="文字項目2"
          prepend-icon="mdi-text"
          outlined
          maxlength="100"
        ></v-text-field>
        <v-text-field
          v-model="comcha3"
          label="文字項目3"
          prepend-icon="mdi-text"
          outlined
          maxlength="100"
        ></v-text-field>
        <v-text-field
          v-model.number="comnum1"
          label="数値項目1"
          prepend-icon="mdi-numeric"
          type="number"
          step="0.0001"
          outlined
        ></v-text-field>
        <v-text-field
          v-model.number="comnum2"
          label="数値項目2"
          prepend-icon="mdi-numeric"
          type="number"
          step="0.0001"
          outlined
        ></v-text-field>
        <v-text-field
          v-model.number="comnum3"
          label="数値項目3"
          prepend-icon="mdi-numeric"
          type="number"
          step="0.0001"
          outlined
        ></v-text-field>
        <DatePicker
          v-model="comdat1"
          label="日付項目1"
          prepend-icon="mdi-calendar"
          outlined
          type="day"
        />
        <DatePicker
          v-model="comdat2"
          label="日付項目2"
          prepend-icon="mdi-calendar"
          outlined
          type="day"
        />
        <DatePicker
          v-model="comdat3"
          label="日付項目3"
          prepend-icon="mdi-calendar"
          outlined
          type="day"
        />
        <v-text-field
          v-model.number="sort"
          label="ソート順"
          prepend-icon="mdi-numeric"
          type="number"
          outlined
        ></v-text-field>
        <v-radio-group
          v-model="selectDelete"
          row
          label="削除フラグ:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
          <v-radio label="削除" :value="SelectsDelete.Delete"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import CodeMaster from "@/models/CodeMaster";
const SelectsDelete = {
  Default: 0,
  Delete: 1
};
export default {
  name: "CodeMasterDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      codegroups: [],
      selectCodeGroup: { code: "", name: "" },
      code: "",
      name: "",
      bikou: "",
      comcha1: "",
      comcha2: "",
      comcha3: "",
      comnum1: null,
      comnum2: null,
      comnum3: null,
      comdat1: null,
      comdat2: null,
      comdat3: null,
      selectDelete: SelectsDelete.Default,
      SelectsDelete,
      sort: null
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    async postRecord(param) {
      await this.$post(this.Paths.codeMaster, param);
      this.add = false;
    },
    async putRecord(param) {
      await this.$put(this.Paths.codeMaster, param);
    },
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      this.$loading();
      try {
        const param = new CodeMaster(
          this.selectCodeGroup.code,
          this.code != null ? this.code : "",
          this.name != null ? this.name : "",
          this.bikou != null ? this.bikou : "",
          this.comcha1 != null ? this.comcha1 : "",
          this.comcha2 != null ? this.comcha2 : "",
          this.comcha3 != null ? this.comcha3 : "",
          this.comnum1 > 0 ? String(this.comnum1) : "",
          this.comnum2 > 0 ? String(this.comnum2) : "",
          this.comnum3 > 0 ? String(this.comnum3) : "",
          this.comdat1 != null ? this.comdat1 : "",
          this.comdat2 != null ? this.comdat2 : "",
          this.comdat3 != null ? this.comdat3 : "",
          this.selectDelete,
          this.sort > 0 ? String(this.sort) : ""
        );

        console.log("submit_param", param);
        if (this.add) {
          await this.postRecord(param);
        } else {
          await this.putRecord(param);
        }
        this.$info("更新しました。", "コードマスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/code-master";
      this.$router.push(path);
    },
    async getCodeGroups() {
      console.log("getCodeGroups");
      return await this.$get(this.Paths.codeGroupPull);
    },
    setDateStr(target) {
      if (target == null) return "";

      const year = target.getFullYear();
      const month = target.getMonth() + 1;
      const day = target.getDate();

      return year + "-" + ("0000000000" + month).slice(-2) + "-" + day;
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;

    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.codegroups = await this.getCodeGroups();

      if (!this.add) {
        this.codegroups = this.codegroups.filter(e => e.code === this.args.kbn);
        if (this.codegroups.length > 0) {
          this.selectCodeGroup.code = this.codegroups[0].code;
          this.selectCodeGroup.name = this.codegroups[0].name;
        }
        this.code = this.args.code;
        this.name = this.args.name;
        this.bikou = this.args.bikou;
        this.comcha1 = this.args.comcha1;
        this.comcha2 = this.args.comcha2;
        this.comcha3 = this.args.comcha3;
        this.comnum1 = this.args.comnum1;
        this.comnum2 = this.args.comnum2;
        this.comnum3 = this.args.comnum3;

        if (this.args.comdat1 != null) {
          const dat = new Date(this.args.comdat1);
          this.comdat1 =
            dat.getFullYear().toString() +
            "-" +
            (dat.getMonth() + 1).toString() +
            "-" +
            dat.getDate().toString();
        }

        if (this.args.comdat2 != null) {
          const dat = new Date(this.args.comdat2);
          this.comdat2 =
            dat.getFullYear().toString() +
            "-" +
            (dat.getMonth() + 1).toString() +
            "-" +
            dat.getDate().toString();
        }

        if (this.args.comdat3 != null) {
          const dat = new Date(this.args.comdat3);
          this.comdat3 =
            dat.getFullYear().toString() +
            "-" +
            (dat.getMonth() + 1).toString() +
            "-" +
            dat.getDate().toString();
        }
        //this.comdat1 = this.args.comdat1;
        //this.comdat2 = this.args.comdat2;
        //this.comdat3 = this.args.comdat3;

        this.sort = this.args.sort;
        this.args.delflg == 0
          ? (this.selectDelete = SelectsDelete.Default)
          : (this.selectDelete = SelectsDelete.Delete);
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
